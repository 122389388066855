import { collection, query, orderBy, addDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export const useAddUser = () => {
  const firestore = useFirestore();
  return async (userInfo: {
    name?: string;
    email: string;
    authId: string;
    admin?: boolean;
  }) => {
    try {
      const docRef = await addDoc(collection(firestore, 'users'), userInfo);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};
