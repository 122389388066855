import React from 'react';
import styled from 'styled-components';

const StyledPage = styled.div`
  padding: 16px 8px 12px 8px;
`;
interface IProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<IProps> = (props) => (
  <StyledPage>{props.children}</StyledPage>
);

const Header: React.FC<IProps> = (props) => <div>{props.children}</div>;

const StyledH1 = styled.h1`
  margin: 0 0 12px 0;
  font-size: 24px;
`;
const Title: React.FC<IProps> = (props) => (
  <StyledH1>{props.children}</StyledH1>
);

const Content: React.FC<IProps> = (props) => <div>{props.children}</div>;
export const Page = {
  Wrapper,
  Header,
  Title,
  Content,
};
